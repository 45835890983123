<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <BreadForm
      :role="role"
      :model="model"
      :url="`${this.$api.servers.event}/api/v1/en/moderator/finisher/edit`"
      action="edit"
      :self="me"
      :callbackSuccess="callbackSuccess"
    ></BreadForm>
    <!-- EOC -->
  </v-container>
  </template>
  
  <script>
    //BOC:[model]
    import model from '@/models/items/finisher'
    //EOC
    import BreadForm from '@/components/Bread/BreadForm'
    import { mapState } from 'vuex'
    export default {
      components:{
        BreadForm,
      },
      computed: mapState({
        school: state => state.school.data,
      }),
      props:[
        'self',
      ],
      data: () => ({
        //BOC:[breadcrumbs]
        breadcrumbs: [],
        //EOC
        //BOC:[self]
        me:{
          id:null,
        },
        //EOC
        //BOC:[model]
        model: model,
        //EOC
        //BOC:[role]
        role:'Moderator',
        //EOC
      }),
      created() {
        //BOC:[guard]
        if(!this.model[this.role].edit) {
          this.$router.replace(this.$_getRouteRead(this.role,this.model.key,this.$route.params.id,this.self))
          return
        }
        //EOC
        //BOC:[self]
        if(this.self) {
          this.me = this.$_.cloneDeep(this.self)
        }
        else {
          this.$router.replace(this.$_getRouteRead(this.role,this.model.key,this.$route.params.id))
        }
        //EOC
        //BOC:[breadcrumbs]
        this.breadcrumbs.push({
          text:this.$t("model.name.dashboard"),
          to:{name:'Page'+this.role+'Dashboard'},
          exact:true,
        })
        //
        this.breadcrumbs.push({
          text:this.$t("model.name." + this.model.name.plural.toLowerCase()),
          to:this.$_getRouteBrowse(this.role,this.model.key),
          exact:true,
        })
        //
        this.breadcrumbs.push({
          text:(this.me.name) ? this.me.name : `${this.$t("model.name." + this.model.name.singular.toLowerCase())} ${this.$route.params.id}`,
          to:this.$_getRouteRead(this.role,this.model.key,this.$route.params.id,this.self),
          exact:true,
        })
        //
        this.breadcrumbs.push({
          text:this.$t("action.edit"),
          to:this.$_getRouteEdit(this.role,this.model.key,this.$route.params.id,this.self),
          exact:true,
        })
        //EOC
      },
      mounted() {
        //
      },
      methods: {
        callbackSuccess(resp) {
          this.$router.push(this.$_getRouteRead(this.role,this.model.key,resp.id,resp))
        },
      }
    }
  </script>